import React from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import Title from '../common/Title';
import { useTranslation } from 'react-i18next';
import CustomButton from '../common/CustomButton';
import BrandsRank from '../../assets/top_brands.png';
import BrandsRankMobile from '../../assets/top_brands_mobile.png';

const Rank = ({ openContact }: { openContact: () => void }) => {
  const { t } = useTranslation();
  const mobile = useMediaQuery('(max-width:600px)');

  return (
    <Grid
      item
      xs={12}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: {
          xs: '4rem 2rem',
          sm: '5.65rem 2rem 5rem',
        },
        width: '100%',

        '& img': {
          width: {
            xs: '100%',
            sm: '80%',
          },
          maxWidth: '1000px',
          height: 'auto',
          marginBottom: '3rem',
        },
      }}
    >
      <Title
        title="rank.title"
        dark
        topTitle="rank.topTitle"
        red
        sx={{
          marginBottom: '4rem',
        }}
      />
      <img src={mobile ? BrandsRankMobile : BrandsRank} alt="rank" />

      <CustomButton title={t('rank.button')} red onClick={openContact} />
    </Grid>
  );
};

export default Rank;

