import React from 'react';
import Home from './components/Home';
// import Dashboard from './components/Back/Dashboard/Dashboard';
import { createBrowserRouter } from 'react-router-dom';

const routes = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  // {
  //   path: '/backoffice',
  //   element: <Dashboard />,
  // },
]);

export default routes;

