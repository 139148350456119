import React, { useEffect, useState } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import Image1 from '../../../../assets/carousel_image_1.png';
import Image2 from '../../../../assets/carousel_image_2.png';
import Image3 from '../../../../assets/carousel_image_3.png';
import ArrowButton from '../../ArrowButton';
import Title from '../../Title';
import { useTranslation } from 'react-i18next';

interface CarouselItem {
  id: number;
  order: number;
  image: string;
}

const carouselData: CarouselItem[] = [
  {
    id: 1,
    order: 0,
    image: Image1,
  },
  {
    id: 2,
    order: 1,
    image: Image2,
  },
  {
    id: 3,
    order: 2,
    image: Image3,
  },
];

const BigCardCarousel: React.FC = () => {
  const [cards, setCards] = useState<CarouselItem[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(1);
  const theme = useTheme();
  const { t } = useTranslation();

  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const tablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const CARD_HEIGHT = mobile ? 400 : tablet ? 550 : 700;

  useEffect(() => {
    setCards(carouselData);
  }, []);

  const handlePrev = () => {
    const newCards = cards.map((card) => {
      if (card.order === cards.length - 1) {
        return {
          ...card,
          order: 0,
        };
      }
      return {
        ...card,
        order: card.order + 1,
      };
    });
    setCards(newCards);
    if (currentIndex === 1) {
      setCurrentIndex(cards.length);
    } else {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNext = () => {
    const newCards = cards.map((card) => {
      if (card.order === 0) {
        return {
          ...card,
          order: cards.length - 1,
        };
      }
      return {
        ...card,
        order: card.order - 1,
      };
    });
    setCards(newCards);
    if (currentIndex === cards.length) {
      setCurrentIndex(1);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          width: '100%',
          position: 'relative',
          height: CARD_HEIGHT + 50,
          marginTop: '6rem',
        }}
      >
        {cards.map((item) => {
          const isLast = item.order === cards.length - 1;
          const width = (isLast ? 100 : 100 - item.order * 5) + '%';
          const height =
            (isLast ? CARD_HEIGHT : CARD_HEIGHT - item.order * 50) + 'px';
          const top = isLast ? '5%' : '-' + item.order * 2 + '%';
          let opacity = isLast ? 0 : 1;
          const zIndex = isLast ? 100 : 100 - item.order * 2;
          const isActive = item.order === 0;

          return (
            <Box
              sx={{
                width: width,
                height: height,
                backgroundImage: `url(${item.image})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'absolute',
                top: top,
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: zIndex,
                opacity: opacity,
                transition: isLast
                  ? 'all .5s ease-in-out, z-index .5s linear'
                  : 'all .5s ease-in-out',
                pointerEvents: 'none',
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-start',
                padding: '5rem',
                flexDirection: 'column',
              }}
              key={'big_carousel_card_' + item.id}
            >
              <Title
                title={`discover2.carousel.${item.id}.title`}
                maxWidth="600px"
                align="left"
                sx={{
                  transition: 'all 1s ease',
                  opacity: isActive ? 1 : 0,
                }}
              />
              <Typography
                variant="body2"
                sx={{
                  color: 'white',
                  fontWeight: 400,
                  textAlign: 'left',
                  lineHeight: '155%',
                  fontSize: '1rem',
                  maxWidth: '500px',
                  transition: 'all 1s ease',
                  opacity: isActive ? 1 : 0,
                }}
                component="div"
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: t(`discover2.carousel.${item.id}.description`),
                  }}
                  style={{
                    fontFamily: 'Arial',
                  }}
                ></div>
              </Typography>
            </Box>
          );
        })}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <ArrowButton reverse onClick={handlePrev} />
          <ArrowButton onClick={handleNext} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontFamily: 'Roboto',
              fontSize: '.875rem',
              fontWeight: 300,
              lineHeight: '175%',
              letterSpacing: '0.96px',
            }}
          >
            0{currentIndex}
          </Typography>
          <Box
            sx={{
              width: '48px',
              height: '2px',
              margin: '0 .5rem',
              backgroundColor: 'rgba(0,0,0,0.3)',
            }}
          >
            <Box
              sx={{
                width: (currentIndex / cards.length) * 100 + '%',
                height: '100%',
                transition: 'all .2s ease-in-out',
                backgroundColor: '#000',
              }}
            ></Box>
          </Box>
          <Typography
            variant="body2"
            sx={{
              fontFamily: 'Roboto',
              fontSize: '.875rem',
              fontWeight: 300,
              lineHeight: '175%',
              letterSpacing: '0.96px',
            }}
          >
            0{cards.length}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default BigCardCarousel;

