import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomButton from '../common/CustomButton';
import Title from '../common/Title';
import HorizontalCarousel from '../common/Carousels/HorizontalCarousel/HorizontalCarousel';

const Discover = ({
  openContact,
  methodologyRef,
}: {
  openContact: () => void;
  methodologyRef: React.RefObject<HTMLDivElement>;
}) => {
  const { t } = useTranslation();

  return (
    <Grid
      item
      xs={12}
      sx={{
        padding: '2rem',
        maxWidth: '1250px !important',
        margin: 'auto',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      ref={methodologyRef}
      id="methodology"
    >
      <Grid container item xs={12} sx={{ marginTop: '5rem' }}>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            paddingBottom: {
              xs: '1rem',
              md: '2rem',
            },
          }}
        >
          <Title title="discover.title2" dark align="left" />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            paddingRight: {
              xs: '0',
              md: '2rem',
            },
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: 'black',
              fontWeight: 400,
              textAlign: 'left',
              lineHeight: '155%',
              margin: '.75rem 0 .5rem',
              fontSize: '1rem',
              fontFamily: 'Arial',

              '& sup': {
                fontSize: '.6em',
                lineHeight: '.5',
              },
            }}
          >
            {t('discover.description2')}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            paddingLeft: {
              xs: '0',
              md: '2rem',
            },
          }}
        >
          <Grid item xs={12}>
            <Typography
              variant="body1"
              sx={{
                color: 'black',
                fontWeight: 700,
                textAlign: 'left',
                lineHeight: '155%',
                margin: '.75rem 0 1rem',
                fontSize: '1.1rem',
                fontFamily: 'Arial',

                '& sup': {
                  fontSize: '.6em',
                  lineHeight: '.5',
                },
              }}
            >
              {t('discover.subtitle')}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: 'black',
                fontWeight: 400,
                textAlign: 'left',
                lineHeight: '155%',
                margin: '.75rem 0 .5rem',
                fontSize: '1rem',
                fontFamily: 'Arial',

                '& sup': {
                  fontSize: '.6em',
                  lineHeight: '.5',
                },
              }}
            >
              {t('discover.description3')}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: 'black',
                fontWeight: 400,
                textAlign: 'left',
                lineHeight: '155%',
                margin: '.25rem 0 2rem',
                fontSize: '1rem',
                fontFamily: 'Arial',

                '& sup': {
                  fontSize: '.6em',
                  lineHeight: '.5',
                },
              }}
            >
              {t('discover.description4')}
            </Typography>
            <CustomButton
              title={t('discover.button')}
              small
              red
              onClick={openContact}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} sx={{ marginTop: '5rem' }}>
        <HorizontalCarousel
          items={[
            {
              title: t('discover.carousel.1.title'),
              description: t('discover.carousel.1.description'),
            },
            {
              title: t('discover.carousel.2.title'),
              description: t('discover.carousel.2.description'),
            },
            {
              title: t('discover.carousel.3.title'),
              description: t('discover.carousel.3.description'),
            },
            {
              title: t('discover.carousel.4.title'),
              description: t('discover.carousel.4.description'),
            },
          ]}
        />
      </Grid>
    </Grid>
  );
};

export default Discover;

