import React from 'react';
import SmallBackgroundCard from '../../../../assets/background_datas.jpg';
import BackgroundCard from '../../../../assets/background_datas@2x.jpg';
import { Box, Typography } from '@mui/material';
import { HorizontalCarouselCardProps } from './HorizontalCarousel.type';

const HorizontalCarouselCard: React.FC<HorizontalCarouselCardProps> = ({
  title,
  description,
}) => {
  return (
    <Box
      sx={{
        minWidth: { xs: 320, sm: 600 },
        height: 'auto',
        backgroundImage: {
          xs: `url(${SmallBackgroundCard})`,
          xl: `url(${BackgroundCard})`,
        },
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        padding: '2.2rem 2.5rem',
        borderRadius: '10px',
        marginRight: '15px',
        scrollSnapAlign: 'start',
      }}
    >
      <Typography
        variant="h3"
        sx={{
          color: 'white',
          fontWeight: 500,
          textAlign: 'left',
          lineHeight: '117%',
          letterSpacing: '1.4px',
          fontSize: '2rem',
          marginBottom: '3.5rem',
          maxWidth: '90%',
        }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: 'white',
          fontWeight: 400,
          textAlign: 'left',
          lineHeight: '160%',
          fontFamily: 'Arial',
          fontSize: '1rem',
          '& sup': {
            fontSize: '.6em',
            lineHeight: '.5',
          },
        }}
      >
        {description}
      </Typography>
    </Box>
  );
};

export default HorizontalCarouselCard;

