import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import backgroundHero from '../assets/background_hero.png';
import Navbar from './Navbar';
import Nav from './Nav';
import CustomButton from './common/CustomButton';
import { useTranslation } from 'react-i18next';
import Title from './common/Title';

interface HeroProps {
  isContactOpen: boolean;
  openContact: () => void;
  visibleSection: string;
  heroRef: React.RefObject<HTMLDivElement>;
}

const Hero: React.FC<HeroProps> = ({
  isContactOpen,
  openContact,
  visibleSection,
  heroRef,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid
      item
      xs={isContactOpen ? 7 : 12}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        margin: isContactOpen ? { xs: 0, sm: '1rem' } : '1rem',
        height: 'calc(100vh - 2rem)',
        background:
          'url(' + backgroundHero + '), lightgray 50% / cover no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: '10px',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: isContactOpen
          ? { md: '2rem 3rem', xs: '2rem 0' }
          : '2rem 3rem',
        transition: 'all 1s ease',
        position: 'relative',
        width: isContactOpen
          ? { md: '58.34%', sm: '41.66%', xs: '0px' }
          : 'calc(100vw - 2rem)',
        maxWidth: isContactOpen
          ? { md: '58.34%', sm: '41.66%', xs: '0px' }
          : '100%',
        overflow: 'hidden',
        '& *': {
          pointerEvents: isContactOpen ? 'none' : 'all',
        },
      }}
      ref={heroRef}
    >
      <Navbar onClick={openContact} isContactOpen={isContactOpen} />
      <Grid
        item
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: isContactOpen ? '80%' : '1400px',
          width: '100%',
          padding: isContactOpen ? '0' : '0 2rem',
          position: 'absolute',
          bottom: isContactOpen ? { xs: '50%', sm: '0rem' } : '50%',
          left: isContactOpen ? { xs: '3rem', xl: '6rem' } : '50%',
          transform: isContactOpen
            ? { sm: 'translate(0%, 0%)', xs: 'translate(-50%, 50%)' }
            : 'translate(-50%, 50%)',
          transition: 'all 1s ease',
          opacity: isContactOpen
            ? {
                xs: 0,
                sm: 1,
              }
            : 1,
        }}
      >
        <Typography
          variant="body1"
          sx={{
            color: 'white',
            fontFamily: 'Baikal',
            textAlign: 'center',
            fontSize: '1rem',
            fontWeight: 300,
            letterSpacing: '0.0525rem',
            opacity: isContactOpen ? 0 : 1,
            transition: 'all 1s ease',
          }}
        >
          {t('hero.subtitle')}
        </Typography>
        <Title
          title={isContactOpen ? t('hero.title2') : t('hero.title')}
          variant="h1"
          align={isContactOpen ? (mobile ? 'center' : 'left') : 'center'}
          sx={{
            fontSize: {
              lg: isContactOpen ? '3.5rem' : '3.75rem',
              md: '3.75rem',
              sm: '3rem',
              xs: '2.75rem',
            },
            margin: '.75rem 0 1.5rem',
          }}
        />
        <CustomButton
          title={
            <div dangerouslySetInnerHTML={{ __html: t('hero.button') }}></div>
          }
          sx={{
            opacity: isContactOpen ? 0 : 1,
            transition: 'all 1s ease',
          }}
          onClick={() => {
            openContact();
          }}
        />
      </Grid>
      <Nav
        sx={{
          opacity: isContactOpen ? 0 : 1,
        }}
        selected={visibleSection}
        openContact={openContact}
      />
    </Grid>
  );
};

export default Hero;

