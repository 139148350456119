import React from 'react';
import { Grid } from '@mui/material';
import Title from '../common/Title';
import BigCarousel from '../common/Carousels/BigCarousel/BigCarousel';

const Discover2 = () => {
  return (
    <Grid
      item
      xs={12}
      id="key_trends"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '4rem 2rem 0',
        width: '100%',
        maxWidth: '1250px !important',
        margin: 'auto',
      }}
    >
      <Title title="discover2.title" dark maxWidth="1100px" />
      <BigCarousel />
    </Grid>
  );
};

export default Discover2;

