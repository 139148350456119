import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// import Backend from 'i18next-http-backend';
import enTranslations from './en/translations.json';

i18n
  // .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // backend: {
    //   loadPath: `/api.php/records/translations`,
    //   crossDomain: true,
    //   parse: (data: any, lang: any) => {
    //     const translations: any = JSON.parse(data).records;
    //     const newTranslations: any = {};
    //     translations.forEach((translation: any) => {
    //       newTranslations[translation.key] = translation[lang];
    //     });

    //     return newTranslations;
    //   },
    // },
    resources: {
      en: {
        translations: enTranslations,
      },
    },
    defaultNS: 'translations',
    ns: ['translations'],
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;

