import React from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import SmallBackgroundSuccessful from '../../assets/background_black.jpg';
import BackgroundSuccessful from '../../assets/background_black@2x.jpg';
import Title from '../common/Title';
import { useTranslation } from 'react-i18next';
import SuccessImg from '../../assets/brands_grid.png';
import SuccessImgMobile from '../../assets/brands_grid_mobile.png';
import CustomButton from '../common/CustomButton';

const Successful = ({
  openContact,
  keyRef,
}: {
  openContact: () => void;
  keyRef: React.RefObject<HTMLDivElement>;
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery('(max-width:600px)');
  return (
    <Grid
      item
      container
      sx={{
        padding: { xs: '4rem 2rem', sm: '5rem 2rem' },
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        backgroundImage: {
          xs: `url(${SmallBackgroundSuccessful})`,
          xl: `url(${BackgroundSuccessful})`,
        },
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
      id="key"
      ref={keyRef}
    >
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          paddingLeft: {
            xs: 0,
            md: '2rem',
          },
          paddingBottom: {
            xs: 0,
            md: '2rem',
          },
        }}
      >
        <Title title="successful.title" align="center" />
      </Grid>

      <Grid item xs={12} md={1}></Grid>

      <Grid
        item
        xs={10}
        md={9}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '4rem',
        }}
      >
        <img
          src={isMobile ? SuccessImgMobile : SuccessImg}
          alt="Brands"
          style={{
            width: '100%',
          }}
        />
        <CustomButton
          sx={{
            marginTop: '3rem',
          }}
          title={t('successful.button')}
          onClick={openContact}
          red
        />
      </Grid>
      <Grid item xs={12} md={1}></Grid>
    </Grid>
  );
};

export default Successful;

