import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Logo from '../assets/logo.png';
import CustomButton from './common/CustomButton';
// import LanguageChange from './common/LanguageChange';
import Circle from '../assets/circle.svg';

interface NavbarProps {
  onClick: () => void;
  isContactOpen?: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ onClick, isContactOpen }) => {
  const { t } = useTranslation();
  return (
    <Grid
      item
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: '100%',
      }}
    >
      <img
        src={Logo}
        alt="logo"
        style={{
          width: '200px',
          maxWidth: '40%',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: 'auto',
          transition: 'all 1s ease',
          opacity: isContactOpen ? '0' : '1',
        }}
      >
        {/* <LanguageChange /> */}
        <Box
          sx={{
            width: '1rem',
          }}
        ></Box>
        <CustomButton
          title={t('contact.contact')}
          onClick={onClick}
          iconAfter={<img src={Circle} alt="circle" />}
          noHover
          wide
        />
      </Box>
    </Grid>
  );
};

export default Navbar;

