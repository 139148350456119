import {
  Alert,
  Box,
  Fade,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from './common/IconButton';
// import LanguageChange from './common/LanguageChange';
import CustomInput from './common/CustomInput';
import CustomButton from './common/CustomButton';
import CustomCheckbox from './common/CustomCheckbox';

const URL_API = 'https://frm.havas.com/';

interface ContactProps {
  open: boolean;
  closeContact: () => void;
}

interface TermsProps {
  t: (key: string) => string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
}

const Terms: React.FC<TermsProps> = ({ t, onChange, checked }) => {
  // Add Checkbox from MUI with text
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
      }}
    >
      <FormControlLabel
        control={<CustomCheckbox checked={checked} onChange={onChange} />}
        label={
          <Typography
            sx={{
              color: 'black',
              fontFamily: 'Arial',
              fontSize: '1rem',
              fontWeight: 300,
              letterSpacing: '0.0525rem',
              marginLeft: '.5rem',

              '& a': {
                color: 'secondary.main',
                textDecoration: 'underline',
                fontFamily: 'Arial',
              },
            }}
            dangerouslySetInnerHTML={{
              __html: t('contact.terms'),
            }}
          ></Typography>
        }
      />
    </Box>
  );
};

const Contact: React.FC<ContactProps> = ({ open, closeContact }) => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [company, setCompany] = React.useState('');
  const [termsAccepted, setTermsAccepted] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [messageType, setMessageType] = React.useState<'success' | 'error'>(
    'success'
  );
  const [hasSent, setHasSent] = React.useState(false);

  const showMessage = (messgae: string, type: 'success' | 'error') => {
    setMessage(messgae);
    setMessageType(type);

    setTimeout(() => {
      setMessage('');
      setMessageType('success');
    }, 5000);
  };

  React.useEffect(() => {
    if (open) {
      setHasSent(false);
    }
  }, [open]);

  const clearForm = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setCompany('');
    setTermsAccepted(false);
  };

  const sendForm = async () => {
    if (!termsAccepted || !firstName || !lastName || !email || !company) {
      showMessage(t('contact.fields_error'), 'error');
      return;
    }
    setIsLoading(true);
    try {
      const url = encodeURI(
        `${URL_API}sendMail.php?firstname=${firstName}&lastname=${lastName}&email=${email}&company=${company}`
      );
      await fetch(url, {
        method: 'POST',
      }).catch((err) => {
        throw err;
      });
      showMessage('', 'success');
      clearForm();
      setHasSent(true);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      showMessage(t('contact.error'), 'error');
      setIsLoading(false);
      setHasSent(false);
    }
  };

  const toggleTerms = () => {
    setTermsAccepted((prev) => !prev);
  };

  return (
    <Grid
      sx={{
        opacity: open ? '1' : '0',
        width: open ? { md: '41.66%', sm: '58.34%', xs: '100%' } : '0%',
        transition: 'all 1s ease',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        gap: '3rem',
        alignItems: 'flex-start',
        zIndex: open ? '1' : '-1',
        overflow: 'hidden',
        padding: open ? { lg: '3rem', xs: '2.5rem' } : 0,
        maxHeight: '100vh',
      }}
      component="form"
      noValidate
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'flex-end',
        }}
      >
        <Typography
          variant="body1"
          sx={{
            color: 'black',
            fontFamily: 'Baikal',
            textAlign: 'left',
            fontSize: { xs: '1.2rem', sm: '1rem' },
            fontWeight: 300,
            letterSpacing: '0.0525rem',
            transition: 'all 1s ease',
            width: 'fit-content',
          }}
        >
          {t('contact.subtitle')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: 'auto',
          }}
        >
          {/* <LanguageChange dark /> */}

          <IconButton
            onClick={closeContact}
            sx={{
              marginLeft: '1rem',
              maxWidth: 40,
              maxHeight: 40,
            }}
          />
        </Box>
      </Box>

      {!hasSent ? (
        <>
          <Typography
            variant="h2"
            sx={{
              color: 'black',
              fontWeight: '500',
              textAlign: 'left',
              fontSize: { xs: '1.45rem', md: '1.4rem', lg: '1.45rem' },
              lineHeight: '105%',
              maxWidth: '28rem',

              '& sup': {
                fontSize: '.6em',
                lineHeight: '.5',
              },
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: t('contact.title'),
              }}
            ></div>
          </Typography>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: '1fr 1fr' },
              gap: 2,
              width: '100%',
            }}
          >
            <CustomInput
              label={t('contact.firstName')}
              onChange={(e) => setFirstName(e.currentTarget.value)}
              autocomplete="given-name"
            />
            <CustomInput
              label={t('contact.lastName')}
              onChange={(e) => setLastName(e.currentTarget.value)}
              autocomplete="family-name"
            />
          </Box>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { sm: '1fr' },
              gap: 4,
              width: '100%',
            }}
          >
            <CustomInput
              label={t('contact.email')}
              onChange={(e) => setEmail(e.currentTarget.value)}
              autocomplete="email"
            />
          </Box>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { sm: '1fr' },
              gap: 4,
              width: '100%',
            }}
          >
            <CustomInput
              label={t('contact.company')}
              onChange={(e) => setCompany(e.currentTarget.value)}
              autocomplete="organization"
            />
          </Box>

          <Terms t={t} onChange={toggleTerms} checked={termsAccepted} />
          <CustomButton
            onClick={sendForm}
            title={isLoading ? t('loading...') : t('contact.button')}
            red
            disabled={!termsAccepted || isLoading}
            textFitContent
          />
        </>
      ) : (
        <>
          <Fade in>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  color: 'green',
                  fontWeight: '500',
                  fontSize: { xs: '1.45rem', md: '1.4rem', lg: '1.45rem' },
                  lineHeight: '105%',
                  maxWidth: '28rem',
                  paddingBottom: '1rem',
                }}
              >
                <div>{t('contact.success')}</div>
              </Typography>
            </Box>
          </Fade>
          <div></div>
        </>
      )}
      {message && (
        <Alert
          severity={messageType}
          sx={{
            position: 'fixed',
            bottom: '2rem',
            right: '2rem',
            zIndex: 1,
          }}
        >
          {message}
        </Alert>
      )}
    </Grid>
  );
};

export default Contact;

