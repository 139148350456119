import { Box, Grid, Typography } from '@mui/material';
import { t } from 'i18next';
import logoRed from '../../assets/logo_red.png';

const Team = () => {
  return (
    <Grid
      item
      id="team"
      xs={12}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: {
          xs: '2rem',
          sm: '5.65rem 2rem 5rem',
        },
        width: '100%',
        '& img': {
          width: {
            xs: '300px',
            sm: '400px',
          },
          marginBottom: '2rem',
        },
      }}
    >
      <img src={logoRed} alt="Logo" />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          maxWidth: '1200px',
          textAlign: 'center',
        }}
      >
        <Typography variant="h5" sx={{ marginBottom: '2rem' }}>
          {t('team.description1')}
        </Typography>
        <Typography variant="h5" sx={{ marginBottom: '2rem' }}>
          {t('team.description2')}
        </Typography>
      </Box>
    </Grid>
  );
};

export default Team;

